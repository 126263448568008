<template>
  <div class="hold-transition">
    <div class="modal fade" id="modal-form-asignacion">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <b style="font-style: italic; font-size: 13px">
              <h5 class="modal-title text-white" v-if="subasta">
                Volumenes ofertados por cliente |
                <b style="font-style: italic; font-size: 12px">
                  Subasta # {{ subasta.id }} crudos Semanas
                  {{ numeroSemanaIni }} y {{ numeroSemanaFin - 1 }} ({{
                    subasta.fecha_ini
                  }}
                  / {{ subasta.fecha_fin }})</b
                >
              </h5>
            </b>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
              @click="limpiarModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" v-if="subasta">
              <div class="col-lg-3 col-6">
                <div class="small-box alert-default-success">
                  <div class="inner">
                    <div class="col-md-11" style="font-size: 10px">
                      <h4><b>Producto</b></h4>
                      <div v-for="item in subasta.productos" :key="item.id">
                        Crudo {{ item.producto.nombre }} ({{ item.api }} API y
                        {{ item.s }} %S)
                      </div>
                    </div>
                  </div>
                  <div class="icon">
                    <i class="ion ion-bag"></i>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box alert-default-warning">
                  <div class="inner">
                    <div class="col-md-11" style="font-size: 10px">
                      <h4><b>Punto de Entrega</b></h4>
                      <div v-for="item in subasta.productos" :key="item.id">
                        Crudo {{ item.producto.nombre }} :
                        {{ item.sitio.dirreccion }}
                      </div>
                    </div>
                  </div>
                  <div class="icon">
                    <i class="ion ion-location"></i>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box alert-default-info">
                  <div class="inner">
                    <div class="col-md-11" style="font-size: 10px">
                      <h4><b>Volumen</b></h4>
                      <div v-for="item in subasta.productos" :key="item.id">
                        Crudo {{ item.producto.nombre }} : Hasta
                        {{ item.cant_vh }} Ctks/D ({{ item.volumen }} BD)
                      </div>
                    </div>
                  </div>
                  <div class="icon">
                    <i class="ion ion-soup-can-outline"></i>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-6">
                <div class="small-box alert-default-danger">
                  <div class="inner">
                    <div class="col-md-11">
                      <h4><b>Vigencia</b></h4>
                      <p style="font-size: 10px">
                        Semana S (Comprendida de lunes a domingo) <br />
                        Semanas {{ numeroSemanaIni }} y
                        {{ numeroSemanaFin - 1 }}
                        ({{ subasta.fecha_ini }} / {{ subasta.fecha_fin }})
                      </p>
                    </div>
                  </div>
                  <div class="icon">
                    <i class="ion ion-calendar"></i>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="subasta">
              <div
                class="card mb-2"
                v-for="(prod, indicePro) in productsAgroups"
                :key="prod.id"
                :class="getCardColorCl(indicePro)"
              >
                <div class="card-header p-2 pl-4">
                  <h4 class="card-title w-100">
                    <a class="d-block w-100 collapsed">
                      <i class="fas fa-coins fa-lg"> </i>
                      Histórico {{ prod[0].producto.nombre }}
                    </a>
                  </h4>
                </div>
                <div class="card-body table-responsive">
                  <div class="row" v-if="form">
                    <!-- Cliente Anterior -->
                    <div class="col-lg-2 col-md-2 col-sm-12">
                      <div class="form-group">
                        <label for="">Cliente Anterior</label>
                        <v-select
                          class="form-control form-control-sm p-0 mr-3"
                          v-model="cliente_ant[indicePro]"
                          placeholder="Nombre"
                          label="razon_social"
                          :options="listasForms.clientes"
                          @search="
                            buscarCliente(
                              prod[0].producto_liquido_id,
                              prod[0].ofertas
                            )
                          "
                          :class="[
                            form[indicePro].cliente_ant_id == null
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          @input="getSelectClienteAnt(indicePro)"
                        ></v-select>
                      </div>
                    </div>
                    <!-- Cliente Nuevo -->
                    <div class="col-lg-2 col-md-2 col-sm-12">
                      <div class="form-group">
                        <label for="">Cliente Nuevo</label>
                        <v-select
                          class="form-control form-control-sm p-0 mr-3"
                          v-model="cliente_nuevo[indicePro]"
                          placeholder="Nombre"
                          label="razon_social"
                          :options="listasForms.clientes"
                          :disabled="form[indicePro].cliente_ant_id == null"
                          :class="[
                            form[indicePro].cliente_nuevo_id == null
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          @search="
                            buscarCliente(
                              prod[0].producto_liquido_id,
                              prod[0].ofertas
                            )
                          "
                          @input="getSelectClienteNew(indicePro)"
                        ></v-select>
                      </div>
                    </div>
                    <!-- Fecha Inicial -->
                    <div class="col-lg-2 col-md-2 col-sm-12">
                      <div class="form-group">
                        <label for="">Fecha Inicial</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form[indicePro].fecha_ini"
                          @change="validaFechas(indicePro, prod[0].ofertas)"
                          :disabled="
                            form[indicePro].cliente_ant_id == null ||
                              form[indicePro].cliente_nuevo_id == null
                          "
                          :class="
                            form[indicePro].fecha_ini == null
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                    </div>
                    <!-- Fecha Final -->
                    <div class="col-lg-2 col-md-2 col-sm-12">
                      <div class="form-group">
                        <label for="">Fecha Final</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form[indicePro].fecha_fin"
                          @change="validaFechas(indicePro, prod[0].ofertas)"
                          :disabled="form[indicePro].fecha_ini == null"
                          :class="
                            form[indicePro].fecha_fin == null
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                    </div>
                    <!-- Cantidad Anterior -->
                    <div class="col-lg-2 col-md-2 col-sm-12">
                      <div class="form-group">
                        <label for="">Cantidad</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="form[indicePro].cant"
                          @change="asignacionCantidad(indicePro)"
                          :disabled="form[indicePro].fecha_fin == null"
                          :class="
                            form[indicePro].cant == null ||
                            form[indicePro].cant == ''
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                    </div>
                    <!-- Botón Guardar -->
                    <div class="col-lg-2 col-md-2 col-sm-12">
                      <div class="form-group">
                        <button
                          type="button"
                          class="btn btn-primary"
                          style="cursor: pointer"
                          v-show="
                            form[indicePro].cant &&
                              form[indicePro].fecha_ini &&
                              form[indicePro].fecha_fin &&
                              form[indicePro].cliente_ant_id &&
                              form[indicePro].cliente_nuevo_id
                          "
                          @click="save(prod[0].id, prod[0].com_subasta_id)"
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                    <div
                      class="card mb-2 col-md-12"
                      v-for="product /* indiProduct */ in productsAgroups[
                        indicePro
                      ]"
                      :key="product.id"
                    >
                      <div class="card-header">
                        <i class="fas fa-calendar"></i>
                        {{ product.fecha_ini }} / {{ product.fecha_fin }}
                        <b> | </b> {{ product.cant_vh }} Ctks/D ({{
                          product.volumen
                        }}
                        BD)
                        <div class="btn-group float-right">
                          <button
                            class="btn btn-primary"
                            type="button"
                            data-toggle="collapse"
                            data-target="#historicoClient"
                            aria-expanded="false"
                            aria-controls="historicoClient"
                            @click="mostrarHistorico(indicePro)"
                          >
                            <i class="fas fa-history"> Histórico</i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div
                          class="card mb-2 col-md-12"
                          v-for="(ofert, indiOfert) in product.ofertas"
                          :class="indiOfert == 0 ? 'alert-default-primary' : ''"
                          :key="ofert.id"
                        >
                          <div class="card-header">
                            <div class="row">
                              <div class="col-md-10">
                                <i class="fas fa-user-tie fa-lg"></i>
                                <b> Cliente: </b>{{ ofert.cliente.razon_social
                                }}<b> | </b>
                                <i class="fas fa-dollar-sign fa-lg"></i>
                                <b> Oferta:</b> {{ ofert.precio_oferta }} US$B
                              </div>
                            </div>
                          </div>
                          <div class="card-header">
                            <b style="font-style: italic; font-size: 12px">
                              Precio Oferta: Brent{{
                                product.precio_piso != 0
                                  ? product.nSigno +
                                    " " +
                                    product.precio_piso +
                                    " " +
                                    product.nMoneda
                                  : product.nSigno + " " + "Oferta"
                              }}</b
                            >
                          </div>
                          <div class="card-body table-responsive">
                            <table
                              class="table table-hover table-sm table-striped"
                            >
                              <thead>
                                <tr>
                                  <th></th>
                                  <th
                                    v-for="detalles in ofert.detalles"
                                    :key="detalles.id"
                                    class="text-center"
                                  >
                                    {{ detalles.fecha }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="text-bold">Ofertado</td>
                                  <td
                                    v-for="detalles in ofert.detalles"
                                    :key="detalles.id"
                                    class="text-center"
                                  >
                                    <div class="d-flex justify-content-center">
                                      <input
                                        type="text"
                                        class="form-control form-control-sm col-md-6 text-center"
                                        v-model="detalles.cant_vehi"
                                        disabled
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div
                            v-show="historicoVisible == indicePro"
                            class="collapse mb-2 col-md-12"
                            id="historicoClient"
                          >
                            <div class="card card-body">
                              <table
                                class="table table-bordered table-striped table-hover table-sm"
                              >
                                <thead class="thead-dark">
                                  <tr class="text-center">
                                    <th>Fecha Oferta</th>
                                    <th>Ofertado Antes</th>
                                    <th>Ofertado Nuevo</th>
                                    <th>Usuario</th>
                                    <th>Fecha Edición</th>
                                  </tr>
                                </thead>
                                <tbody
                                  v-for="detalles in ofert.detalles"
                                  :key="detalles.id"
                                >
                                  <tr
                                    v-for="historico in detalles.historicos_estados"
                                    :key="historico.id"
                                  >
                                    <td class="text-center">
                                      {{ historico.fecha }}
                                    </td>
                                    <td class="text-center">
                                      <span class="badge badge-info">
                                        {{ historico.cant_vh_anterior }}
                                      </span>
                                    </td>
                                    <td class="text-center">
                                      <span class="badge badge-primary">
                                        {{ historico.cant_vh_nuevo }}
                                      </span>
                                    </td>
                                    <td class="text-center">
                                      {{ historico.user.name }}
                                    </td>
                                    <td class="text-center">
                                      {{ historico.fecha_hora }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import moment from "moment";

export default {
  name: "SubastaModalReAsig",
  components: { vSelect },

  data() {
    return {
      subasta: null,
      productsAgroups: null,
      form: [],
      cliente_nuevo: [],
      cliente_ant: [],
      acuDiccionario: {},
      originalValues: {},
      historicoVisible: -1,
      listasForms: {
        clientes: [],
      },
    };
  },

  computed: {
    numeroSemanaIni() {
      const dateIni = new Date(this.subasta.fecha_ini); // Convert fecha_ini to a Date object
      return this.getWeekNumber(dateIni);
    },
    numeroSemanaFin() {
      const dateFin = new Date(this.subasta.fecha_fin); // Convert fecha_fin to a Date object
      return this.getWeekNumber(dateFin);
    },
  },

  methods: {
    async getIndex(id = null) {
      if (id) {
        await this.$nextTick(); // Esperar a que se actualice el DOM
        this.$parent.cargando = true;
        this.subasta = null;
        this.form = [];
        const me = this;
        await axios
          .get("/api/com/subastas/" + id)
          .then(async (response) => {
            this.subasta = response.data;
            await this.agruparProductos();
            this.$parent.cargando = false;
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error:" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.$parent.cargando = false;
          });
      }
    },

    async agruparProductos() {
      const productsAgroups = this.subasta.productos.reduce((acc, sub) => {
        const productoId = sub.producto_liquido_id;

        if (!acc[productoId]) {
          acc[productoId] = [];
        }

        acc[productoId].push(sub);

        return acc;
      }, {});

      this.productsAgroups = Object.values(productsAgroups);
      await this.getform();
    },

    async getform() {
      var form1 = [];
      var clien_ant = [];
      var clien_new = [];
      for (let i = 0; i < this.productsAgroups.length; i++) {
        let asignacion = {
          fecha_ini: null,
          fecha_fin: null,
          cliente_ant_id: null,
          cliente_nuevo_id: null,
          cant: null,
        };
        let client = [];
        let client1 = [];
        clien_ant.push(client);
        clien_new.push(client1);
        form1.push(asignacion);
      }
      this.cliente_ant = clien_ant;
      this.cliente_nuevo = clien_new;
      this.form = form1;
    },

    validaFechas(indice, ofertas) {
      let fechaInSubasta = this.subasta.fecha_ini;
      let fechaFinSubasta = this.subasta.fecha_fin;

      if (
        this.form[indice].fecha_ini < fechaInSubasta ||
        this.form[indice].fecha_ini > fechaFinSubasta ||
        this.form[indice].fecha_fin > fechaFinSubasta
      ) {
        this.form[indice].fecha_ini = null;
        this.form[indice].fecha_fin = null;
        this.$swal({
          customClass: {
            container: "my-swal",
          },
          icon: "error",
          title: `La fechas diligenciada no se encuentran en el rango de la fechas de la subasta...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }

      if (this.form[indice].fecha_ini > this.form[indice].fecha_fin) {
        this.form[indice].fecha_ini = null;
        this.form[indice].fecha_fin = null;
        this.$swal({
          customClass: {
            container: "my-swal",
          },
          icon: "error",
          title: `La fecha inicial no puede ser mayor a la fecha final...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }

      if (this.form[indice].fecha_ini && this.form[indice].fecha_fin) {
        let fechasRango = this.obtenerFechasEntre(
          this.form[indice].fecha_ini,
          this.form[indice].fecha_fin
        );

        fechasRango.forEach((rango) => {
          ofertas.forEach((oferta) => {
            if (this.form[indice].cliente_ant_id == oferta.cliente_id) {
              let fechaOferta = oferta.detalles.filter((detalle) => {
                if (detalle.fecha == rango) {
                  return detalle;
                }
              });
              console.log(fechaOferta);
              if (fechaOferta.length === 0) {
                this.$swal({
                  icon: "warning",
                  title: "No existe una oferta.",
                  text:
                    "Por favor validar nuevamente la fecha inical y fecha final seleccionada..",
                  showConfirmButton: false,
                  timer: 4000,
                  timerProgressBar: true,
                });
                this.form[indice].fecha_ini = null;
                this.form[indice].fecha_fin = null;
              }
            }
          });
        });
      }
    },

    async buscarCliente(producto_liquido_id, ofertas) {
      let me = this;
      let ofertas_ids = ofertas.map((oferta) => oferta.id);

      await axios
        .get("/api/com/clientes/listaClienteSubasta", {
          params: {
            ofertas_ids: ofertas_ids,
            producto_liquido_id: producto_liquido_id,
          },
        })
        .then(function(response) {
          if (response.data.length > 0) {
            me.listasForms.clientes = response.data;
          } else {
            me.$swal({
              icon: "error",
              title: "Clientes no encontrados",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getSelectClienteAnt(indice) {
      this.form[indice].cliente_ant_id = null;
      if (this.cliente_ant[indice]) {
        this.form[indice].cliente_ant_id = this.cliente_ant[indice].id;
      }
      this.asignacionCliente(indice);
    },

    getSelectClienteNew(indice) {
      this.form[indice].cliente_nuevo_id = null;
      if (this.cliente_nuevo[indice]) {
        this.form[indice].cliente_nuevo_id = this.cliente_nuevo[indice].id;
      }
      this.asignacionCliente(indice);
    },

    asignacionCliente(indice) {
      if (
        this.form[indice].cliente_ant_id &&
        this.form[indice].cliente_nuevo_id
      ) {
        if (
          this.form[indice].cliente_nuevo_id == this.form[indice].cliente_ant_id
        ) {
          this.$swal({
            icon: "warning",
            title: "El cliente anterior no puede ser igual al cliente nuevo..!",
            text: "Por favor validar nuevamente los clientes",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cliente_ant = [];
          this.cliente_nuevo = [];
          this.form[indice].cliente_ant_id = null;
          this.form[indice].cliente_nuevo_id = null;
        }
      }
    },

    asignacionCantidad(indice) {
      let producto = this.productsAgroups[indice][0];
      if (producto.ofertas.length > 0) {
        producto.ofertas.forEach((oferta) => {
          if (this.form[indice].cliente_ant_id == oferta.cliente_id) {
            oferta.detalles.forEach((detalle) => {
              if (this.form[indice].cant > detalle.cant_vehi) {
                this.$swal({
                  icon: "warning",
                  title: "El cliente no tiene ese cantidad asignada..!",
                  text: "Por favor validar nuevamente las cantidades",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.form[indice].cant = null;
              }
            });
          }
        });
      }
    },

    obtenerFechasEntre(fechaI, fechaF) {
      let fechas = [];
      let fechaActual = moment.utc(fechaI); // Utiliza moment.utc para manejar la zona horaria de forma explícita
      let fechaFin = moment.utc(fechaF);

      while (fechaActual <= fechaFin) {
        fechas.push(fechaActual.format("YYYY-MM-DD"));
        fechaActual.add(1, "day"); // Utiliza add() en lugar de setDate() para evitar problemas con la zona horaria
      }
      return fechas;
    },

    save(producto_subasta, subasta_id) {
      axios({
        method: "PUT",
        url: "/api/com/subastas/AsignarAdjudicados",
        data: {
          form: this.form,
          oferta: {
            com_subasta_id: subasta_id,
            com_subasta_producto_id: producto_subasta,
          },
        },
      })
        .then(() => {
          this.form = [];
          this.getIndex(subasta_id);
          this.$swal({
            icon: "success",
            title: "Se han asignado exitosamente los proponentes adjudicados",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    mostrarHistorico(indice) {
      // Cambiar la visibilidad del histórico según el índice
      this.historicoVisible = indice;
    },

    async valAdjudicado(detalles, cliente) {
      const valueAcept = detalles.cant_aceptada;
      const valueOfert = detalles.cant_vehi;
      if (valueAcept > valueOfert) {
        this.$swal({
          icon: "warning",
          title: "El valor digitado ha superado el ofertado!!",
          text: "Por favor validar nuevamente el valor para esta fecha",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });

        detalles.cant_aceptada = this.originalValues[detalles.id];
      } else {
        if (valueAcept > 0 && valueAcept != "" && valueAcept != null) {
          this.originalValues[detalles.id] = valueAcept;
        } else {
          if (this.originalValues[detalles.id]) {
            delete this.originalValues[detalles.id];
          }
        }
      }

      if (
        cliente.detalles.some(
          (det) =>
            det.cant_aceptada != "" &&
            det.cant_aceptada != 0 &&
            det.cant_aceptada != null
        )
      ) {
        cliente.ganador = true;
      } else {
        cliente.ganador = false;
      }
    },

    getWeekNumber(date) {
      const onejan = new Date(date.getFullYear(), 0, 1);
      const weekNum = Math.ceil(
        ((date - onejan) / 86400000 + onejan.getDay() + 1) / 7
      );
      return weekNum;
    },

    limpiarModal() {
      this.subasta = null;
      this.calendarMounted = false;
    },

    getCardColorCl(index) {
      const colors = [
        "card-navy",
        "card-secondary",
        "card-info",
        "card-warning",
      ];
      const colorIndex = index % colors.length;
      return colors[colorIndex];
    },
  },
};
</script>
<style>
.my-swal {
  z-index: 20000 !important;
}
</style>
